<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='省：'>
                        <el-select v-model="filters.provinceID" placeholder="请选择" clearable @clear='clearProvince' @change='handleProChange'>
                            <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='市：'>
                        <el-select v-model="filters.cityID" placeholder="请选择" clearable @clear='clearCity' @change='handleCityChange'>
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='区/县：'>
                        <el-select v-model="filters.regionID" placeholder="请选择" clearable @clear='clearRegion'>
                            <el-option
                            v-for="item in options3"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='审核状态：'>
                        <el-select v-model="filters.status" placeholder="请选择" clearable @change='getAgencyInfoListPage'>
                            <el-option
                            v-for="item in options4"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='关键字：'>
                        <el-input v-model.trim='filters.keyWord' placeholder='请输入手机号' clearable @clear='getAgencyInfoListPage' @input='() => (filters.keyWord = filters.keyWord.replace(/\s/,""))' @keyup.enter.native='getAgencyInfoListPage'></el-input>
                    </el-form-item>
                    <el-form-item label='培训机构：'>
                        <el-input v-model.trim='filters.name' placeholder='请输入培训机构名称' clearable @clear='getAgencyInfoListPage' @input='() => (filters.name = filters.name.replace(/\s/,""))' @keyup.enter.native='getAgencyInfoListPage'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        :data="tableData"
        border
        style="width: 95%"
        highlight-current-row
        @current-change='selectCurrentRow'
        v-loading='listLoading'>
            <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
            <el-table-column  prop="ContactPerson" label="姓名" width="" align='center'></el-table-column>
            <el-table-column  prop="ContactPersonPhoneNumber" label="手机号" width="120" align='center'></el-table-column>
            <el-table-column  prop="Province" label="省份" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="City" label="城市" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="Region" label="区/县" width="" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="Name" label="培训机构名称" width="130" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="Address" label="详细地址" width="130" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="ScaleCount" label="规模人数" width="100" align='center'></el-table-column>
            <el-table-column  prop="RegisteredCapital" label="注册资本(万)" width="110" align='center'></el-table-column>
            <el-table-column  prop="Status" label="审核状态" width="100" align='center' fixed="right">
                <template slot-scope='scope'>
                    <el-tag type='error' v-if='scope.row.Status == 0'>未审核</el-tag>
                    <el-tag type='success' v-if='scope.row.Status == 1'>已通过</el-tag>
                    <el-tag type='warning' v-if='scope.row.Status == 2'>未通过</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="IsEnable" label="启用标识" width="100" align='center' fixed="right">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable == true?"success":"warning"'>{{scope.row.IsEnable == true?'启用':'禁用'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column  prop="BusinessLicenseImgUrl" label="营业执照" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.BusinessLicenseImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.BusinessLicenseImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="SchoolLicenceImgUrl" label="办学许可证" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.SchoolLicenceImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.SchoolLicenceImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="ReceptionOneImgUrl" label="前台外观一" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.ReceptionOneImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.ReceptionOneImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="ReceptionTwoImgUrl" label="前台外观二" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.ReceptionTwoImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.ReceptionTwoImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="ClassRoomOneImgUrl" label="理论学习场景一" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.ClassRoomOneImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.ClassRoomOneImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="ClassRoomTwoImgUrl" label="理论学习场景二" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.ClassRoomTwoImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.ClassRoomTwoImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="OperationOneImgUrl" label="实操学习一" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.OperationOneImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.OperationOneImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="OperationTwoImgUrl" label="实操学习二" width="120" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.OperationTwoImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.OperationTwoImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="LessonQRCodeImgUrl" label="学习入口二维码" width="130" align='center' show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.LessonQRCodeImgUrl" alt="" style='width:120px;height:150px'> 
                        <img slot="reference" :src="scope.row.LessonQRCodeImgUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column  prop="PassRate" label="通过率" width="" align='center' fixed="right"></el-table-column>
            <el-table-column  prop="TrainStudentNumber" label="培训人数" width="100" align='center'></el-table-column>
            <el-table-column  prop="WechatAppletID" label="微信小程序ID" width="115" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="WechatAppletHomePageUrl" label="微信小程序首页路径" width="130" align='center' show-overflow-tooltip></el-table-column>
            <el-table-column  prop="CreateTime" label="创建时间" width="110" align='center' show-overflow-tooltip :formatter="formatCreateTime" fixed="right"></el-table-column>
            <el-table-column  prop="CreateUserID" label="创建人" width="" align='center' fixed="right"></el-table-column>
            <el-table-column  prop="Remarks" label="备注" width="" align='center' show-overflow-tooltip fixed="right"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import {getAreaDictionaryList,getAgencyInfoListPage,approveAgencyInfo,deleteAgencyInfo} from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                name:'',
                status:'',
                isEnable:'',
                contactPersonPhoneNumber:'',
                provinceID:'',
                cityID:'',
                regionID:''
            },
            options1:[],
            options2:[],
            options3:[],
            options4:[
                {label:'未审核',value:0},
                {label:'已通过',value:1},
                {label:'未通过',value:3}
            ],
            tableData:[],
            listLoading:false,
            currentRow:{},
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 获取列表数据
        getData(){
            var params = {
                name:this.filters.name,
                status:this.filters.status,
                isEnable:this.filters.isEnable,
                contactPersonPhoneNumber:this.filters.contactPersonPhoneNumber,
                provinceID:this.filters.provinceID,
                cityID:this.filters.cityID,
                regionID:this.filters.regionID
            }
            this.listLoading = true
            getAgencyInfoListPage(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.listLoading = false
            })
        },
        // 获取省市区信息
        getArea(params){
            switch(params.level){
                case 0:
                    // 得到省
                    this.getProvince(params)
                    break;
                case 1:
                    // 得到市
                    this.getCity(params)
                    break;
                case 2:
                    // 得到区/县
                    this.getRegion(params)
                    break;
            }
        },
        getProvince(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.options1 = data.map(item => {
                        var obj = {}
                        obj.label = item.Name
                        obj.value = item.ID
                        return obj
                    }).reverse()
                }
            })
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.options2 = data.map(item => {
                        var obj = {}
                        obj.label = item.Name
                        obj.value = item.ID
                        return obj
                    }).reverse()
                }
            })
        },
        getRegion(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    var data = res.data.Response
                    this.options3 = data.map(item => {
                        var obj = {}
                        obj.label = item.Name
                        obj.value = item.ID
                        return obj
                    }).reverse()
                }
            })
        },
        handleProChange(value){
            var params = {
                level:1,
                parentId:value
            }
            this.getArea(params) 
        },
        handleCityChange(value){
            var params = {
                level:2,
                parentId:value
            }
            this.getArea(params)
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
        // 分页相关操作
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        // 查询
        getAgencyInfoListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 通过审核
        approveAgencyInfo(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择一行数据')
            }else{
                this.$confirm('该数据是否要通过审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    var params = {id:row.ID}
                    approveAgencyInfo(Qs.stringify(params)).then(res =>{
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() =>{})
                }).catch(() => {})               
            }
        },
        // 删除
        handleDel(){
            var row = this.currentRow
            if(JSON.stringify(row) == '{}'){
                this.$message.warning('请选择要删除的一行数据')
            }else{
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    var params = {id:row.ID}
                    deleteAgencyInfo(Qs.stringify(params)).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})            
            }
        },

        // 清除省
        clearProvince(){
            this.filters.provinceID = ''
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.options2 = []
            this.options3 = []
        },
        // 清除市
        clearCity(){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.options3 = []
        },
        // 清除区/县
        clearRegion(){
            this.filters.regionID = ''
        }
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
        // 获取省份信息
        var params = {
            level:0,
            parentId:''
        }
        this.getArea(params)
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>